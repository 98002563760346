/* Center align button */
button[block] {
    display: block;
    width: 100%;
}

/* Style for textarea */
textarea {
    resize: none;
}

/* Alert styling */
.alert {
    margin-top: 20px;
}

/* Center align radio buttons */
.text-center {
    text-align: center;
}

.radio-buttons {
    display: flex;
    justify-content: center;
}

.radio-buttons .form-check {
    margin: 0 10px;
}

/* Record list and item styles */
.record-list {
    margin-top: 20px;
}

.record-item {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
}